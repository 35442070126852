<script>
import {
  UilArrowGrowth,
  UilInfoCircle,
  UilRefresh,
  UilAngleUp,
  UilAngleDown,
  UilDirection,
} from '@iconscout/vue-unicons'
import {
  BTooltip, BFormInput, BRow, BCol, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import
{
  VIcon,
  VList,
  VListItemAction,
  VListItemTitle,
  VMenu,
  VListItem,
  VRow,
  VListItemContent,
  VBtn,
  VListItemGroup,
} from 'vuetify/lib'

export default {
  components: {
    VIcon,
    VList,
    VListItemAction,
    VListItemTitle,
    VMenu,
    VListItem,
    VRow,
    VListItemContent,
    VBtn,
    VListItemGroup,
    BFormCheckbox,
    BButton,
    BTooltip,
    UilArrowGrowth,
    UilInfoCircle,
    UilRefresh,
    UilAngleUp,
    UilAngleDown,
    UilDirection,
    BFormInput,
    BRow,
    BCol,
  },
  props: {
    column_headers: {
      type: Array,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    display_columns_check: {
      type: Array,
      default: null,
    },
    index_table: {
      type: String,
      default: '',
    },
    columsCheck: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      formatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      searchQuery: '',
      enableSorting: true,
      sortKey: null, // Key of the column to sort by
      sortOrder: null, // 'asc' or 'desc'
      allSelected: false,
      displayColumnsCheck: this.display_columns_check,
      fields: this.columsCheck,
      localItems: [...this.items],
      showDropdown: false,
      filters: {
        vendor: [], // Unique values for filtering
      },
      activeFilters: {
        vendor: [], // Selected filters
      },
    }
  },
  computed: {
    filteredItems() {
      let filtered = this.localItems

      // Apply search filter
      if (this.searchQuery.trim()) {
        const query = this.searchQuery.toLowerCase()
        filtered = filtered.filter(item => this.column_headers.some(header => {
          const value = item[header.value]
          return (
            value
                  && value
                    .toString()
                    .toLowerCase()
                    .includes(query)
          )
        }))
      }

      // Apply sorting
      if (this.sortKey) {
        const key = this.sortKey
        filtered = filtered.slice().sort((a, b) => {
          const aValue = a[key]
          const bValue = b[key]

          if (aValue == null) return 1
          if (bValue == null) return -1

          if (typeof aValue === 'number' && typeof bValue === 'number') {
            return this.sortOrder === 'asc' ? aValue - bValue : bValue - aValue
          }

          const aStr = aValue.toString().toLowerCase()
          const bStr = bValue.toString().toLowerCase()
          if (this.sortOrder === 'asc') {
            return aStr.localeCompare(bStr)
          }
          return bStr.localeCompare(aStr)
        })
      }
      filtered = filtered.filter(row => this.activeFilters.vendor.includes(row.vendor))
      return filtered
    },
  },

  watch: {
    items: {
      handler(newVal, oldVal) {
        this.localItems = this.localItems.filter(i => i.id !== oldVal[0].id)
        this.localItems.push(newVal[0])
      },
      deep: true, // Ensures the watcher detects changes within the object
      immediate: false, // Set to true if you want the handler to run immediately after component is created
    },
  },
  mounted() {
    this.filters.vendor = [...new Set(this.items.map(row => row.vendor))].sort()
    this.activeFilters.vendor = this.filters.vendor
  },
  methods: {
    swapItem(item) {
      this.$emit('swapItem', item)
    },
    alignRight(value) {
      const exclude = ['name', 'internal_note', 'actions', 'vendor', 'subcategory', 'element']
      return !exclude.includes(value)
    },
    showSort(value) {
      const exclude = ['supply_price_per_unit', 'actions']
      return !exclude.includes(value)
    },
    sortBy(column) {
      if (this.sortKey === column) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortKey = column
        this.sortOrder = 'asc'
      }
    },
    showColumnsFiltered(col) {
      return this.display_columns_check.includes(col)
    },
    valueFomatter(value) {
      const num = Number(value) // Convert value to a number
      if (!isNaN(num) && typeof num === 'number' && value.toString().trim() === num.toString()) {
        return this.formatter.format(num) // Format if it's a valid number
      }
      return value // Return the original value if not a valid numbereturn unchanged if neither string nor number
    },
    sumField(key) {
      if (key == 'total_price') {
        let sum = 0
        this.filteredItems.forEach(i => {
          if (i.specification_swap) {
            sum += i.specification_swap.total_price * 1 + i.specification_swap.total_delivery_install * 1
          } else {
            sum += i.total_price * 1
          }
        })
        return sum
      }
      return this.filteredItems.reduce((a, b) => a + (b[key] || 0), 0)
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    },
    toggleAll(col) {
      this.activeFilters[col] = [...this.filters[col]]
    },
    clearAll(col) {
      this.activeFilters[col] = []
    },
  },
}

</script>

<template>
  <div>
    <b-row>
      <b-col cols="10" />
      <b-col cols="2">
        <b-form-input
          v-if="localItems.length > 0"
          v-model="searchQuery"
          append-icon="mdi-magnify"
          label="Search"
          hide-details
          outlined
          placeholder="Search"
          class="mr-1"
        />
      </b-col>
      <b-col
        cols="12"
        style="overflow-y: auto; min-height: 100px"
      >
        <table
          v-if="localItems.length > 0"
          class="table mb-5"
          style="height: 250px !important"
        >
          <thead>
<!--          start a dummy comment to refresh branch-->
            <tr>
              <th
                v-for="header in column_headers"
                v-if="showColumnsFiltered(header.value)"
                :key="header.value"
                :class="{ 'text-right': alignRight(header.value)}"
                @click="enableSorting && sortBy(header.value)"
              >
                <div class="d-flex" :style="alignRight(header.value)?'justify-content: end;':'' ">
                  {{ header.text }}
                  <span v-if="enableSorting && showSort(header.value)">
                  <uil-angle-up
                      v-if="sortKey === header.value && sortOrder === 'asc'"
                      class="fas fa-sort-up"
                  />
                  <uil-angle-down
                      v-if="sortKey === header.value && sortOrder === 'desc'"
                      class="fas fa-sort-down"
                  />
                  <uil-direction
                      v-if="sortKey !== header.value"
                      class="fas fa-sort"
                  />
                  <div v-if="header.value=='vendor'">
                    <div
                        :id="'table_filter' + index_table"
                        :key="'filter' + index_table"
                    >
                      <v-menu
                          :close-on-content-click="false"
                          :max-width="400"
                          :min-width="300"
                          :attach="'#table_filter' + index_table"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              color="primary"
                              icon
                              v-bind="attrs"
                              v-on="on"
                              style="margin-top: -25px; margin-left: 15px"
                          >
                            <v-icon
                                small
                                :style="
                                activeFilters.vendor &&
                                  activeFilters.vendor.length <
                                  activeFilters.vendor.length
                                  ? 'color:black'
                                  : 'color:#3b3b3b'
                              "
                            >
                              mdi-filter-variant
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-list class="pa-0">
                          <v-list-item-group>
                            <template v-for="(item, index) in filters.vendor">
                              <v-list-item
                                  :key="index"
                                  :value="item"
                                  :ripple="false"
                              >
                                <template>
                                  <v-list-item-action>
                                    <b-form-checkbox
                                        v-model="activeFilters.vendor"
                                        :value="item"
                                    />
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title
                                        style="font-weight: normal; text-transform: none"
                                    >{{
                                        item
                                      }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>
                            </template>
                          </v-list-item-group>
                        </v-list>
                        <v-row
                            no-gutters
                            style="background-color: #fff"
                        >
                          <v-col cols="6">
                            <b-button
                                text
                                block
                                variant="outline-light"
                                style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                                @click="toggleAll('vendor')"
                            >Toggle all</b-button>
                          </v-col>
                          <v-col cols="6">
                            <b-button
                                text
                                block
                                variant="outline-light"
                                style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                                @click="clearAll('vendor')"
                            >Clear all</b-button>
                          </v-col>
                        </v-row>
                      </v-menu>
                    </div>
                  </div>
                </span>
                  <span v-if="header.value === 'supply_price_per_unit'">
                  <uil-info-circle
                      :id="'supply_price_per_unit' + index_table"
                      class="m-0 p-0"
                  />
                  <b-tooltip
                      title="COGS / Unit + Markup / Unit"
                      :target="'supply_price_per_unit' + index_table"
                  />
                </span>
                  <span v-if="header.value === 'unit_price'">
                  <uil-info-circle
                      :id="'unit_price' + index_table"
                      class="m-0 p-0"
                  />
                  <b-tooltip
                      title="Price / Unit + Delivery & install / Unit"
                      :target="'unit_price' + index_table"
                  />
                </span>
                  <span v-if="header.value === 'total_price'">
                  <uil-info-circle
                      :id="'total_price' + index_table"
                      class="m-0 p-0"
                  />
                  <b-tooltip
                      title="(Price / Unit + Delivery & install / Unit) x Quantity"
                      :target="'total_price' + index_table"
                  />
                </span>
                  <span v-if="header.value === 'total_supply_price'">
                  <uil-info-circle
                      :id="'total_supply_price' + index_table"
                      class="m-0 p-0"
                  />
                  <b-tooltip
                      title="(CS Unit Cost + Markup / Unit) x Quantity"
                      :target="'total_supply_price' + index_table"
                  />
                </span>
                  <span v-if="header.value === 'unit_cost'">
                  <uil-info-circle
                      :id="'unit_cost' + index_table"
                      class="m-0 p-0"
                  />
                  <b-tooltip
                      title="Cost of Goods Sold"
                      :target="'unit_cost' + index_table"
                  />
                </span>
                  <span v-if="header.value === 'total_cost'">
                  <uil-info-circle
                      :id="'total_cost' + index_table"
                      class="m-0 p-0"
                  />
                  <b-tooltip
                      title="Cost of Goods Sold x Quantity"
                      :target="'total_cost' + index_table"
                  />
                </span>
                  <span v-if="header.value === 'cost_per_sqft'">
                  <uil-info-circle
                      :id="'cost_per_sqft' + index_table"
                      class="m-0 p-0"
                  />
                  <b-tooltip
                      title="(Cost of Goods Sold x Quantity) / SQFT"
                      :target="'cost_per_sqft' + index_table"
                  />
                </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in filteredItems"
              :key="item.id"
            >
              <td
                v-for="header in column_headers"
                v-if="showColumnsFiltered(header.value)"
                :key="header.value"
                :class="{ 'text-right': alignRight(header.value)}"
              >
                <!-- Check for specification_swap existence -->
                <template v-if="item.specification_swap">
                  <template v-if="header.value === 'name'">
                    <uil-arrow-growth style="color: #ff9f43" />
                    {{ item[header.value] }}
                    <br>
                    <small class="text-muted">
                      {{ item.specification_swap.name }}
                    </small>
                  </template>
                  <template v-else-if="header.value === 'internal_note'">
                    {{ item.specification_swap.internal_note }}
                  </template>
                  <template v-else-if="header.value === 'supply_price_per_unit'">
                    {{ formatter.format(item.specification_swap.unit_cogs) }}
                  </template>
                  <template v-else-if="header.value === 'unit_delivery_install'">
                    {{ formatter.format(item.specification_swap.total_delivery_install) }}
                  </template>
                  <template v-else-if="header.value === 'qty'">
                    {{ item.qty }}
                  </template>
                  <template v-else-if="header.value === 'total_price'">
                    {{ formatter.format(item.specification_swap.total_price*1 + item.specification_swap.total_delivery_install*1) }}
                  </template>
                  <template v-else-if="header.value === 'actions'">
                    <div
                      style="cursor: pointer; width: 100px"
                      @click="swapItem(item)"
                    >
                      <uil-refresh
                        class="logo"
                        size="15"
                      />
                      Swap Spec
                    </div>
                  </template>
                  <template v-else>
                    {{ valueFomatter(item[header.value]) }}
                  </template>
                </template>

                <!-- Fallback if no specification_swap -->
                <template v-else>
                  <template v-if="header.value === 'supply_price_per_unit'">
                    {{ formatter.format(item.supply_price) }}
                  </template>
                  <template v-else-if="header.value === 'unit_delivery_install'">
                    {{ formatter.format(item.unit_delivery_install) }}
                  </template>
                  <template v-else-if="header.value === 'total_price'">
                    {{ formatter.format(item.total_price) }}
                  </template>
                  <template v-else-if="header.value === 'actions'">
                    <div
                      style="cursor: pointer; width: 100px"
                      @click="swapItem(item)"
                    >
                      <uil-refresh
                        class="logo"
                        size="15"
                      />
                      Swap Spec
                    </div>
                  </template>
                  <template v-else-if="header.value === 'qty'">
                    {{ item.qty }}
                  </template>
                  <template v-else>
                    {{ valueFomatter(item[header.value]) }}
                  </template>
                </template>
              </td>
            </tr>
            <tr>
              <td
                v-for="header in column_headers"
                v-if="showColumnsFiltered(header.value)"
                :key="header.value"
                class="bg-light"
                :class="{ 'text-right': alignRight(header.value)}"
              >
                <!--                totals logic-->
                <template>
                  <template v-if="header.value === 'name'">
                    <b>TOTALS</b>
                  </template>
                  <template v-else-if="header.value === 'element'" />
                  <template v-else-if="header.value === 'vendor'" />
                  <template v-else-if="header.value === 'internal_note'" />
                  <template v-else-if="header.value === 'subcategory'" />
                  <template v-else-if="header.value === 'uniformat_name'" />
                  <template v-else-if="header.value === 'unit_cost'" />
                  <template v-else-if="header.value === 'mark_up_per_unit'" />
                  <template v-else-if="header.value === 'supply_price_per_unit'" />
                  <template v-else-if="header.value === 'unit_delivery_install'" />
                  <template v-else-if="header.value === 'unit_price'" />
                  <template v-else-if="header.value === 'actions'" />
                  <template v-else-if="header.value === 'qty'">
                    {{ sumField(header.value) }}
                  </template>
                  <template v-else>
                    <b>{{ formatter.format(sumField(header.value)) }}</b>
                  </template>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>

  </div>
</template>

<style scoped lang="scss">
tr,th{
  width: 11vh;
}
th {
  cursor: pointer;
}
th .fas {
  font-size: 0.8rem;
  color: #888;
}
th:hover .fas {
  color: #000;
}
.b-dropdown-menu {
  background-color: #ffffff !important; /* Set solid white background */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); /* Optional: Add a subtle shadow */
  border: 1px solid #dee2e6; /* Optional: Add a border to match Bootstrap style */
}
</style>
