<template>
  <b-overlay :show="show" large rounded="lg" spinner-variant="primary">
    <div id="tfr-creation" style="font-size: 14px">
      <form-wizard
        color=" rgba(59, 171, 255, 1) "
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Create request"
        back-button-text="Previous"
        @on-complete="formSubmitted"
        ref="form"
        style="overflow: visible !important"
        class="p-0 m-0"
      >
        <!--General Info Tab-->
        <tab-content
          title="General Info"
          style="font-size: 14px"
          class="p-0 m-0"
          :before-change="validationForm"
        >
          <validation-observer ref="accountRules" tag="form">
            <b-row class="p-0 m-0 mx-0">
              <b-col md="12" class="p-0 m-0">
                <h5
                  style="
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 24px;
                    margin-bottom: 14px;
                  "
                >
                  General Info
                </h5>

                <validation-provider
                  #default="{ errors }"
                  name="Revision Name"
                  rules="required"
                >
                  <b-form-group>
                    <label for="InputHelp">Revision Name</label>
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        {{ opp.sf_opportunity_number + "-R" }}
                      </b-input-group-prepend>
                      <b-form-input placeholder="01H" v-model="revision" />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col :cols="spec_set_selected === 0 || spec_set_selected===null ? 12 : 10" class="py-0 pl-0 my-0">
                <validation-provider
                    #default="{ errors }"
                    name="Specification Set"
                    rules="required"
                >
                <div>
                  <h5
                    style="
                      font-weight: 500;
                      font-size: 15px;
                      line-height: 24px;
                      margin-bottom: 0px;
                    "
                  >
                    Default Specification Set
                    <uil-info-circle id="spec_set_selected" />
                    <b-tooltip
                        title="This sets the default specifications for elements in this
                    opportunity. Changes will only apply to new quotes."
                        :target="'spec_set_selected'"
                    />
                  </h5>
                  <v-select
                    style="z-index: 10000"
                    label="name"
                    :options="spec_sets"
                    item-text="name"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    v-model="spec_set_selected"
                    max-height="100px"
                    class="style-chooser"
                    placeholder="Select Specification Set"

                  />
                </div>
                  <small class="text-danger" v-if="errors[0]"
                  >{{ errors[0] }}<br
                  /></small>
                </validation-provider>
              </b-col>
              <b-col cols="1" class="p-0 m-0" v-if="spec_set_selected != 0 && spec_set_selected!=null" :cols="spec_set_selected == 0 ? 0 : 2">
                <b-button style="margin-top: 22.5px" class="ml-1" @click="gotoSpecSet()">
                  Review</b-button
                >
              </b-col>
<!--              <b-col :cols="addition_set_selected === 0 || addition_set_selected===null ? 6 : 4" class="pl-0 py-0 my-0">-->
<!--                <validation-provider-->
<!--                    #default="{ errors }"-->
<!--                    name="Addition Set"-->
<!--                    rules="required"-->
<!--                >-->
<!--                  <div>-->
<!--                    <h5-->
<!--                        style="-->
<!--                      font-weight: 500;-->
<!--                      font-size: 15px;-->
<!--                      line-height: 24px;-->
<!--                      margin-bottom: 0px;-->
<!--                    "-->
<!--                    >-->
<!--                      Default Addition Set-->
<!--                      <uil-info-circle id="addition_set_selected" />-->
<!--                      <b-tooltip-->
<!--                          title="This sets the default additions for elements in this-->
<!--                      opportunity. Changes will only apply to new quotes."-->
<!--                          :target="'addition_set_selected'"-->
<!--                      />-->
<!--                    </h5>-->
<!--                    <v-select-->
<!--                        style="z-index: 10000"-->
<!--                        label="name"-->
<!--                        :options="addition_sets"-->
<!--                        item-text="name"-->
<!--                        :reduce="(option) => option.id"-->
<!--                        :clearable="false"-->
<!--                        v-model="addition_set_selected"-->
<!--                        max-height="100px"-->
<!--                        class="style-chooser-addition"-->
<!--                        placeholder="Select Addition Set"-->
<!--                    />-->
<!--                  </div>-->
<!--                  <small class="text-danger" v-if="errors[0]"-->
<!--                  >{{ errors[0] }}<br-->
<!--                  /></small>-->
<!--                </validation-provider>-->
<!--              </b-col>-->
<!--              <b-col  class="p-0 m-0" v-if="addition_set_selected != 0 && addition_set_selected!=null" :cols="spec_set_selected == 0 ? 0 : 2">-->
<!--                <b-button style="margin-top:22.5px" class="ml-1" @click="gotoAdditionSet()">-->
<!--                  Review</b-button-->
<!--                >-->
<!--              </b-col>-->
              <b-col md="6" class="p-0 m-0 pr-1 mt-1">
                <validation-provider
                  #default="{ errors }"
                  name="Project Address"
                  rules="required"
                >
                  <b-form-group>
                    <label for="InputHelp">Project Address</label>
                    <b-input-group>
                      <b-form-input placeholder="101 - 123 Yonge St." v-model="address" />
                    </b-input-group>
                    <small class="text-danger" v-if="errors[0]"
                      >{{ errors[0] }}<br
                    /></small>
                    <small class="text-muted"
                      >Updating this will update the project page and future
                      revisions.</small
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="p-0 m-0 mt-1">
                <validation-provider #default="{ errors }" name="sqft" rules="required">
                  <b-form-group>
                    <label for="InputHelp">Rentable SQFT</label>
                    <b-input-group append="SQFT">
                      <b-form-input
                        v-model="rentable_sqft"
                        type="number"
                        placeholder="ex.100"
                      />
                    </b-input-group>
                    <small class="text-danger" v-if="errors[0]"
                      >{{ errors[0] }}<br
                    /></small>
                    <small class="text-muted"
                      >Updating this will update the project page and future
                      revisions.</small
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="12" class="p-0 m-0" style="padding-top: 30px">
                <h5
                  style="
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 24px;
                    margin-bottom: 0px;
                  "
                >
                  Project Contact
                </h5>
                <small
                  class="text-muted"
                  style="font-weight: 400; font-size: 12px; line-height: 18px"
                  >Confirm or update client contact information.</small
                >
              </b-col>

              <b-col md="6" class="p-0 m-0 pr-1">
                <validation-provider #default="{ errors }" name="Name" rules="required">
                  <b-form-group>
                    <label for="InputHelp">Name</label>
                    <b-input-group>
                      <b-form-input placeholder="John Smith" v-model="contact_name" />
                    </b-input-group>
                    <small class="text-danger" v-if="errors[0]"
                      >{{ errors[0] }}<br
                    /></small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="p-0 m-0">
                <b-form-group>
                  <label for="InputHelp">Phone</label>
                  <small class="text-muted"> optional</small>
                  <b-input-group>
                    <b-form-input placeholder="905-123-4567" v-model="contact_phone" />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="6" class="p-0 m-0 pr-1">
                <b-form-group>
                  <label for="InputHelp">Email</label>
                  <small class="text-muted"> optional</small>
                  <b-input-group>
                    <b-form-input
                      placeholder="john.smith@theinternet.com"
                      v-model="contact_email"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- personal details tab -->
        <tab-content
          title="Requirements"
          style="font-size: 14px"
          :before-change="validationRequirementsTab"
        >
          <validation-observer ref="requirementsRules" tag="form" class="mx-1">
            <b-col md="12" class="p-0 m-0">
              <h5
                style="
                  font-weight: 500;
                  font-size: 15px;
                  line-height: 24px;
                  margin-bottom: 0px;
                "
              >
                Requirements
              </h5>
              <small
                style="
                  color: #6e6b7b;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                "
                >Enter all requirements for this revision. <b>Only</b> itemized
                requirements will be included in this revision.</small
              >
              <br />
            </b-col>
            <b-col class="p-0 m-0">
              <draggable
                v-model="requirements"
                tag="ul"
                class="list-group list-group-flush cursor-move"
                @change="onChange"
              >
                <b-list-group-item
                  v-for="(item, index) in requirements"
                  :key="index"
                  tag="li"
                >
                  <b-row>
                    <b-col
                      class="p-0 m-0"
                      md="1"
                      style="
                        padding-right: 13px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 24px;
                        color: #6e6b7b;
                      "
                    >
                      {{ item.order }}
                    </b-col>
                    <b-col md="10" class="p-0 m-0">
                      <b-form-group label="Name" label-for="item-name">
                        <b-form-input
                          id="item-name"
                          v-model="item.name"
                          type="text"
                          placeholder="Ex. Custom bench with shelving"
                        />
                        <small class="text-danger" v-if="item.name == ''"
                          >Please add a name to the requirement.</small
                        >
                      </b-form-group>
                      <b-form-group>
                        <label for="item-description">Specification</label>
                        <small class="text-muted"> optional</small>
                        <b-form-textarea
                          id="item-description"
                          placeholder="Describe dimensions, finishes, look and feel, location or attach a link of a reference image. Note if this is required for the client or a nice to have."
                          rows="1"
                          style="height: auto !important"
                          v-model="item.description"
                          :state="
                            item.description
                              ? item.description.length <= maxChar
                              : 0 <= maxChar
                          "
                          :class="
                            item.description
                              ? item.description.length > maxChar
                              : 0 > maxChar
                              ? 'text-danger'
                              : ''
                          "
                        />
                        <small
                          class="textarea-counter-value float-right"
                          :class="
                            item.description
                              ? item.description.length > maxChar
                                ? 'bg-danger'
                                : ''
                              : ''
                          "
                        >
                          <span class="char-count">{{
                            item.description ? item.description.length : 0
                          }}</span>
                          /
                          {{ maxChar }}
                        </small>
                        <!-- <small class="text-danger" v-if="item.description == ''"
                            >Please describe the requirement in more detail.</small
                          > -->
                      </b-form-group>
                    </b-col>
                    <b-col md="1" class="p-0 m-0">
                      <b-button
                        style="border: none; background-color: transparent"
                        variant="light"
                        @click="removeItem(index)"
                        ><uil-ban class="logo" size="15"
                      /></b-button>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </draggable>
              <!-- <pre>{{ requirements }}</pre> -->
              <b-row class="p-0 m-0">
                <!-- Name -->
                <b-col md="12" class="p-0 m-0">
                  <b-form-group label="Name" label-for="item-name">
                    <b-form-input
                      id="item-name"
                      v-model="newRequirement.name"
                      type="text"
                      placeholder="Ex. Custom bench with shelving"
                    />
                    <small class="text-danger" v-if="arrayLenghtError"
                      >Please enter a minimum of one requirement to continue.</small
                    >
                  </b-form-group>
                  <b-form-group>
                    <label for="item-description">Specification</label>
                    <small class="text-muted"> optional</small>
                    <b-form-textarea
                      id="item-description"
                      placeholder="Describe dimensions, finishes, look and feel, location or attach a link of a reference image. Note if this is required for the client or a nice to have."
                      rows="3"
                      style="height: auto !important"
                      v-model="newRequirement.description"
                      :state="newRequirement.description.length <= maxChar"
                      :class="
                        newRequirement.description.length > maxChar ? 'text-danger' : ''
                      "
                    />
                    <small
                      class="textarea-counter-value float-right"
                      :class="
                        newRequirement.description.length > maxChar ? 'bg-danger' : ''
                      "
                    >
                      <span class="char-count">{{
                        newRequirement.description.length
                      }}</span>
                      / {{ maxChar }}
                    </small>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                @click="addRequirement"
                :disabled="addRequirementEnabled"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Add Requirement</span>
              </b-button>
              <validation-provider #default="{ errors }" name="Context">
                <div class="mb-4">
                  <h5
                    class="mt-2"
                    style="
                      font-weight: 500;
                      font-size: 15px;
                      line-height: 24px;
                      margin-bottom: 0px;
                    "
                  >
                    Context
                    <small class="text-muted"> optional</small>
                  </h5>
                  <small
                    class="text-muted"
                    style="font-weight: 400; font-size: 12px; line-height: 18px"
                    >Note any additional context that would help planners and
                    quoters.</small
                  >
                  <b-form-textarea
                    id="item-description"
                    placeholder="Note any additional context for planners and quoters. Describe the client, if they are price sensitive or have preferences about their space that may not be captured in the itemized requirements."
                    :state="notes.length <= 1000"
                    rows="3"
                    v-model="notes"
                    style="margin-top: 14px; height: auto !important"
                  />
                  <small
                    class="textarea-counter-value float-right"
                    :class="notes ? (notes.length > 1000 ? 'bg-danger' : '') : ''"
                  >
                    <span class="char-count">{{ notes.length }}</span> / {{ 1000 }}
                  </small>
                  <small class="text-danger" v-if="notes.length > 1000"
                    >Context max length is 1000 characters</small
                  >
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>
            </b-col>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </div>
    <template #overlay>
      <div class="text-center">
        <div class="loading">
          <div class="effect-1 effects" />
          <div class="effect-2 effects" />
          <div class="effect-3 effects" />
        </div>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { UilBan, UilInfoCircle } from "@iconscout/vue-unicons";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BCardText,
  BInputGroup,
  BInputGroupPrepend,
  BTable,
  BOverlay,
  BFormTextarea,
  BButton,
  BListGroupItem,
  VBToggle,
  BCard,
  BCollapse,
  BTooltip,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import draggable from "vuedraggable";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import "vue-form-wizard/dist/vue-form-wizard.min.css";
export default {
  components: {
    BTooltip,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormFile,
    BCardText,
    BInputGroup,
    BInputGroupPrepend,
    BTable,
    BOverlay,
    BFormTextarea,
    BButton,
    draggable,
    BListGroupItem,
    AppCollapse,
    AppCollapseItem,
    BCard,
    BCollapse,
    UilBan,
    UilInfoCircle,
    BTooltip,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  mixins: [heightTransition],
  props: ["opp"],
  data() {
    return {
      revision: "",
      rentable_sqft: this.opp.rentable_sqft,
      address: this.opp.address,
      contact_name: this.opp.contact_name,
      contact_phone: this.opp.contact_phone,
      contact_email: this.opp.contact_email,
      sf_opportunity_id: this.opp.sf_opportunity_id,
      requirements: [],
      newRequirement: {
        name: "",
        description: "",
      },
      requirementName: "",
      requirementDescription: "",
      nextTodoOrder: 1,
      notes: "",
      attachments_folder: "",
      arrayLenghtError: false,
      maxChar: this.$maxCharDesc,
      show: false,
      spec_set_selected: null,
      spec_sets: [
        {
          name: "Clearspace Base Spec",
          id: 0,
        },
      ],
      addition_set_selected: null,
      addition_sets: [
        {
          name: "Clearspace Base Spec",
          id: 0,
        },
      ],
    };
  },

  computed: {
    addRequirementEnabled() {
      if (this.newRequirement.name == "") {
        return true;
      } else {
        return false;
      }
    },
    incompleteRequirement() {
      var obj = [];
      obj = this.requirements.filter((item) => item.name == "");
      if (obj.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.initTrHeight();
    this.getSpecSets();
    // this.getAdditionSets()
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    formSubmitted() {
      return new Promise((resolve, reject) => {
        this.$refs.requirementsRules.validate().then((success) => {
          if (success) {
            if (this.validateRequirementsDesc()) {
              return false;
            }
            if (this.notes.length > 1000) {
              return false;
            }
            resolve(true);
            this.show = true;
            this.$http
              .post(`/opportunities/${this.sf_opportunity_id}/tfr`, {
                revision: this.revision,
                rentable_sqft: this.rentable_sqft,
                address: this.address,
                contact_name: this.contact_name,
                contact_phone: this.contact_phone,
                contact_email: this.contact_email,
                requirements: this.requirements,
                notes: this.notes,
                attachments_folder: this.attachments_folder,
                spec_set: this.spec_set_selected,
                // addition_set: this.addition_set_selected,
              })
              .then((response) => {
                this.show = false;
                this.$emit("createdTfr", response.data.data);
              })
              .catch((error) => {
                this.show = false;
                this.$emit("errorModal");
              });
          } else {
            reject();
          }
        });
      });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationRequirementsTab() {
      return new Promise((resolve, reject) => {
        this.$refs.requirementsRules.validate().then((success) => {
          if (this.incompleteRequirement) {
            reject();
          }
          if (this.requirements.length == 0) {
            this.arrayLenghtError = true;
          }
          if (success) {
            if (this.requirements.length > 0) {
              this.arrayLenghtError = false;
              resolve(true);
            }
            if (this.requirements.length == 0) {
              this.arrayLenghtError = true;
              reject();
            }
            if (this.notes.length > 1000) {
              reject();
            }
          } else {
            reject();
          }
        });
      });
    },
    validationContextTab() {
      return new Promise((resolve, reject) => {
        this.$refs.contextRules.validate().then((success) => {
          if (success) {
            if (this.notes.length > 1000) {
              reject();
            }
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validateRequirementsDesc() {
      let isVal = false;
      let vm = this;
      let reqInvalid = vm.requirements.filter(
        (r) => r.description && r.description.length > vm.maxChar
      );
      reqInvalid.length > 0 ? (isVal = true) : (isVal = false);
      return isVal;
    },
    addRequirement() {
      // validate over limit char description
      if (this.newRequirement.description.length > this.maxChar) {
        return false;
      }
      this.requirements.push({
        order: this.nextTodoOrder,
        name: this.newRequirement.name,
        description: this.newRequirement.description,
      });
      this.nextTodoOrder = this.nextTodoOrder + 1;
      this.newRequirement.name = "";
      this.newRequirement.description = "";
      this.arrayLenghtError = false;
    },
    removeItem(index) {
      this.requirements.splice(index, 1);
      this.nextTodoOrder = this.nextTodoOrder - 1;
      this.reorder();
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    onChange(event) {
      this.reorder();
    },
    reorder() {
      this.requirements.forEach((item, index) => (item.order = index + 1));
    },
    getSpecSets() {
      this.$http
        .get(`/specifications/sets`)
        .then((response) => {
          let { data } = response;
          this.spec_sets = [this.spec_sets[0], ...data];
          // this.spec_set_selected = this.spec_sets[0].id;
        })
        .catch((error) => {
          this.show = false;
          this.showToast("danger", error.response.data.message);
        });
    },
    getAdditionSets() {
      this.$http
          .get(`/additions/sets`)
          .then((response) => {
            let { data } = response;
            this.addition_sets = [this.addition_sets[0], ...data];
            // this.spec_set_selected = this.spec_sets[0].id;
          })
          .catch((error) => {
            this.show = false;
            this.showToast("danger", error.response.data.message);
          });
    },
    gotoSpecSet() {
      let route = this.$router.resolve("/catalogue/" + this.spec_set_selected + "/edit");
      window.open(route.href);
    },
    gotoAdditionSet() {
      let route = this.$router.resolve("/catalogue-addition/" + this.spec_set_selected + "/edit");
      window.open(route.href);
    },
  },
};
</script>
<style scoped>
.custom_btn {
  color: rgba(59, 171, 255, 1);
  height: 38px;
  margin-left: 10px;
}

.label_tfr {
  font-size: 12px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #b9b9c3;
}

.list-overview {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.span-overview {
  flex: 0 0 60%;
  text-align: right;
}

.list-overview {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.span-overview {
  flex: 0 0 60%;
  text-align: right;
}

.label-overview {
  font-weight: bold;
}

#modal-duplication.modal.modal-footer {
  display: none;
}

#modal-duplication.wizard-nav.wizard-nav-pills {
  display: none;
}

#tfr-creation.modal.modal-footer {
  display: block;
}

#tfr-creation .wizard-nav.wizard-nav-pills {
  display: flex;
}

.vue-form-wizard .wizard-card-footer {
  border-top: 1px solid #efefef !important;
  padding-top: 11px;
}

.active-class-tab {
  background-color: #f1f1f1 !important;
  border-color: #dae1e7 #dae1e7 transparent;
  border-radius: 5px;
}

.nav-tabs .nav-link:after {
  height: 0px;
}

.nav-pills .nav-link,
.nav-tabs .nav-link {
  display: flex;
  align-items: center;
  justify-content: left;
}

.nav-tabs .nav-link {
  max-width: 250px;
}

.nav-vertical {
  max-height: 850px;
  overflow-y: auto;
}

.modal-padding {
  padding: 0.8rem 1.4rem;
}

.salesforce-avatar {
  background: #dfeeff;
  color: #0070ff;
}

.form-control {
  height: 35px !important;
  font-size: 15px !important;
}

[dir] .input-group-text {
  height: 35px !important;
  font-size: 15px !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav {
  padding: 1.42rem 1.42rem 2.14rem 1.42rem !important;
  border-bottom: 1px solid rgba(34, 41, 47, 0.08) !important;
  padding-bottom: 0 !important;
}

.wizard-icon-circle {
  width: 2.61rem !important;
  height: 2.61rem !important;
}
</style>
<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  z-index:10030 !important;
  //position: relative;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
}
.style-chooser-addition .vs__search::placeholder,
.style-chooser-addition .vs__dropdown-toggle,
.style-chooser-addition .vs__dropdown-menu {
  z-index: 1 !important;
  //position: relative;
}

.style-chooser-addition .vs__clear,
.style-chooser-addition .vs__open-indicator {
  fill: #394066;
}
</style>
