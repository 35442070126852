<template>
    <b-overlay :show="show" large rounded="lg" spinner-variant="primary">
      <div class="m-1" >
          <!-- personal details tab -->
          <b-form ref="form">  <validation-observer ref="requirementsRules" tag="form">
              <b-col md="12">
                <h5
                  style="
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 24px;
                    margin-bottom: 0px;
                  "
                >
                  Requirements
                </h5>
                <small
                  style="
                    color: #6e6b7b;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                  "
                  >Enter all requirements for this revision. <b>Only</b> itemized
                  requirements will be included in this revision.</small
                >
                <br />
              </b-col>
              <b-col>
                <draggable
                  v-model="requirements"
                  tag="ul"
                  class="list-group list-group-flush cursor-move"
                  @change="onChange"
                >
                  <b-list-group-item
                    v-for="(item, index) in requirements"
                    :key="index"
                    tag="li"
                    style="border: 1px solid rgba(31, 41, 47, 0.05); border-radius: 5px"
                  >
                    <b-row>
                      <b-col
                        md="1"
                        style="
                          padding-right: 13px;
                          font-weight: 400;
                          font-size: 12px;
                          line-height: 24px;
                          color: #6e6b7b;
                        "
                      >
                        {{ item.order }}
                      </b-col>
                      <b-col md="10">
                        <b-form-group label="Name" label-for="item-name">
                          <b-form-input
                            id="item-name"
                            v-model="item.name"
                            type="text"
                            placeholder="Ex. Custom bench with shelving"
                          />
                          <small class="text-danger" v-if="item.name == ''"
                            >Please add a name to the requirement.</small
                          >
                        </b-form-group>
                        <b-form-group >
                          <label for="item-description">Specification</label>  <small class="text-muted"> optional</small>
                          <b-form-input
                            id="item-description"
                            placeholder="Describe dimensions, finishes, look and feel, location or attach a link of a reference image. Note if this is required for the client or a nice to have."
                            rows="1"
                            v-model="item.description"
                            :state="
                              item.description
                                ? item.description.length <= maxChar
                                : 0 <= maxChar
                            "
                            :class="
                              item.description
                                ? item.description.length > maxChar
                                : 0 > maxChar
                                ? 'text-danger'
                                : ''
                            "
                          />
                          <small
                            class="textarea-counter-value float-right"
                            :class="
                              item.description
                                ? item.description.length > maxChar
                                : 0 > maxChar
                                ? 'bg-danger'
                                : ''
                            "
                          >
                            <span class="char-count">{{
                              item.description ? item.description.length : 0
                            }}</span>
                            /
                            {{ maxChar }}
                          </small>
                          <!-- <small class="text-danger" v-if="item.description == ''"
                            >Please describe the requirement in more detail.</small
                          > -->
                        </b-form-group>
                      </b-col>
                      <b-col md="1">
                        <b-button
                          style="border: none; background-color: transparent"
                          variant="light"
                          @click="removeItem(index)"
                          ><uil-ban class="logo" size="15"
                        /></b-button>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </draggable>
                <!-- <pre>{{ requirements }}</pre> -->
                <b-row>
                  <!-- Name -->
                  <b-col md="12">
                    <b-form-group label="Name" label-for="item-name">
                      <b-form-input
                        id="item-name"
                        v-model="newRequirement.name"
                        type="text"
                        placeholder="Ex. Custom bench with shelving"
                      />
                      <small class="text-danger" v-if="arrayLenghtError"
                        >You need to add at least 1 requirement for the revision</small
                      >
                    </b-form-group>
                    <label for="item-description">Specification</label>
                    <small class="text-muted"> optional</small>
                      <b-form-textarea
                        id="item-description"
                        placeholder="Describe dimensions, finishes, look and feel, location or attach a link of a reference image. Note if this is required for the client or a nice to have."
                        rows="3"
                        v-model="newRequirement.description"
                        :class="
                          newRequirement.description.length > maxChar ? 'text-danger' : ''
                        "
                      />
                      <small
                        class="textarea-counter-value float-right"
                        :class="
                          newRequirement.description.length > maxChar ? 'bg-danger' : ''
                        "
                      >
                        <span class="char-count">{{
                          newRequirement.description.length
                        }}</span>
                        / {{ maxChar }}
                      </small>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  @click="addRequirement"
                  :disabled="addRequirementEnabled"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>Add Requirement</span>
                </b-button>
                <validation-provider #default="{ errors }" name="Context">
                  <div class="mb-4">
                    <h5
                    class="mt-2"
                    style="
                      font-weight: 500;
                      font-size: 15px;
                      line-height: 24px;
                      margin-bottom: 0px;
                    "
                  >
                    Context 
                    <small class="text-muted"> optional</small>
                  </h5>
                  <small
                    class="text-muted"
                    style="font-weight: 400; font-size: 12px; line-height: 18px"
                    >Note any additional context that would help planners and
                    quoters.</small
                  >
                  <b-form-textarea
                    id="item-description"
                    placeholder="Note any additional context for planners and quoters. Describe the client, if they are price sensitive or have preferences about their space that may not be captured in the itemized requirements."
                    :state="notes ? notes.length <= 1000 : 0 <= 1000"
                    rows="3"
                    v-model="notes"
                    style="margin-top: 14px"
                  />
                  <small
                    class="textarea-counter-value float-right"
                    :class="notes ? notes.length > 1000 ? 'bg-danger' : '': ''"
                  >
                    <span class="char-count">{{
                      notes ? notes.length  : 0 
                    }}</span>
                    / {{ 1000 }}
                  </small>
                  <small class="text-danger" v-if="(notes && notes.length) > 1000"
                    >Context max length is 1000 characters</small
                  >
                  <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </validation-provider>
              </b-col>
            <hr>
            <div class="d-flex justify-content-end">
                <b-button class="btn-primary" style="background-color:  rgba(59, 171, 255, 1)  !important ; border-color:  rgba(59, 171, 255, 1)  !important; " @click="formSubmitted" >Save Changes</b-button>
            </div>
            </validation-observer></b-form>
          
      </div>
      <template #overlay>
        <div class="text-center">
          <div class="loading">
            <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" />
          </div>
        </div>
      </template>
    </b-overlay>
  </template>
  
<script>
  import { FormWizard, TabContent } from "vue-form-wizard";
  import vSelect from "vue-select";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCardText,
    BInputGroup,
    BInputGroupPrepend,
    BTable,
    BOverlay,
    BFormTextarea,
    BButton,
    BListGroupItem,
    VBToggle,
    BCard,
    BCollapse,
    BForm
  } from "bootstrap-vue";
  import { UilBan } from "@iconscout/vue-unicons";
  import { required, email } from "@validations";
  import { heightTransition } from "@core/mixins/ui/transition";
  import Ripple from "vue-ripple-directive";
  import draggable from "vuedraggable";
  import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
  import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      FormWizard,
      TabContent,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      vSelect,
      // eslint-disable-next-line vue/no-unused-components
      ToastificationContent,
      BFormFile,
      BCardText,
      BInputGroup,
      BInputGroupPrepend,
      BTable,
      BOverlay,
      BFormTextarea,
      BButton,
      draggable,
      BListGroupItem,
      AppCollapse,
      AppCollapseItem,
      BCard,
      BCollapse,
      UilBan,
    BForm
    },
    directives: {
      "b-toggle": VBToggle,
      Ripple,
    },
    mixins: [heightTransition],
    props: ["opp", "revisionToEdit"],
    data() {
      return {
        revisionModal: [],
        revision: this.revisionToEdit.revision,
        rentable_sqft: this.revisionToEdit.rentable_sqft,
        address: this.revisionToEdit.address,
        contact_name: this.revisionToEdit.contact_name,
        contact_phone: this.revisionToEdit.contact_phone,
        contact_email: this.revisionToEdit.contact_email,
        requirements: this.revisionToEdit.requirements,
        notes: this.revisionToEdit.notes,
        attachments_folder: this.revisionToEdit.attachments_folder_url,
        sf_opportunity_id: this.opp.sf_opportunity_id,
        newRequirement: {
          name: "",
          description: "",
        },
        requirementName: "",
        requirementDescription: "",
        nextTodoOrder: 1,
        arrayLenghtError: false,
        maxChar: this.$maxCharDesc,
        fields: {
          details: "",
        },
        incompleteRequirement: false,
        show: false,
      };
    },
  
    computed: {
      addRequirementEnabled() {
        if (this.newRequirement.name == "") {
          return true;
        } else {
          return false;
        }
      },
    },
    mounted() {
    },
    methods: {
      formSubmitted() {
      
        return new Promise((resolve, reject) => {
          this.$refs.requirementsRules.validate().then((success) => {
            if (success) {
              if (this.validateRequirementsDesc()) {
                return false;
              }
              if ((this.notes && this.notes.length) > 1000) {
                return false;
              }
              if ( this.requirements >= 0) {
                this.validationRequirementsTab()
                return false;
              }
              resolve(true);
              this.show = true;
              this.$http
                .put(
                  `/opportunities/${this.sf_opportunity_id}/tfr/${this.revisionToEdit.id}`,
                  {
                    revision: this.revision,
                    rentable_sqft: this.rentable_sqft,
                    address: this.address,
                    contact_name: this.contact_name,
                    contact_phone: this.contact_phone,
                    contact_email: this.contact_email,
                    requirements: this.requirements,
                    notes: this.notes,
                    attachments_folder: this.attachments_folder,
                  }
                )
                .then((response) => {
                  this.$emit("updatedOptions", response.data.data);
                  //this.showToast('success', 'Revision Updated Successfully', 'Revision Updated');
                  this.show = false;
                  this.$bvModal.hide("tfr-update");
                })
                .catch((error) => {
                  this.show = false;
                  if (error.response.data.message) {
                    this.showToast(
                      "danger",
                      error.response.data.message,
                      "Something went wrong"
                    );
                  } else {
                    this.showToast(
                      "danger",
                      "Contact Digital for support.",
                      "Something went wrong"
                    );
                  }
                });
            } else {
              console.log("object");
              reject();
            }
          });
        });
      },
      validationForm() {
        return new Promise((resolve, reject) => {
          this.$refs.accountRules.validate().then((success) => {
            if (success) {
              resolve(true);
            } else {
              reject();
            }
          });
        });
      },
      validationRequirementsTab() {
         new Promise((resolve, reject) => {
          this.$refs.requirementsRules.validate().then((success) => {
            var obj = this.requirements.some((item) => item.name == "");
            if (obj) {
              this.incompleteRequirement = true;
              reject();
            }
            if (this.requirements.length == 0) {
              this.arrayLenghtError = true;
            }
            this.incompleteRequirement = false;
            if (success) {
              if (this.requirements.length > 0) {
                this.arrayLenghtError = false;
                resolve(true);
              }
              if (this.requirements.length == 0) {
                this.arrayLenghtError = true;
                reject();
              }
              if ((this.notes && this.notes.length) > 1000) {
                reject();
              }
            } else {
              reject();
            }
          });
        });
      },
      validationContextTab() {
        return new Promise((resolve, reject) => {
          this.$refs.contextRules.validate().then((success) => {
            if (success) {
              if (this.notes.length > 1000) {
                reject();
              }
              resolve(true);
            } else {
              reject();
            }
          });
        });
      },
      validationFormSocial() {
        return new Promise((resolve, reject) => {
          this.$refs.socialRules.validate().then((success) => {
            if (success) {
              resolve(true);
            } else {
              reject();
            }
          });
        });
      },
      addRequirement() {
        this.requirements.push({
          order: this.nextTodoOrder,
          name: this.newRequirement.name,
          description: this.newRequirement.description,
        });
        this.nextTodoOrder = this.nextTodoOrder + 1;
        this.newRequirement.name = "";
        this.newRequirement.description = "";
        this.arrayLenghtError = false;
        this.reorder();
      },
      removeItem(index) {
        this.requirements.splice(index, 1);
        this.nextTodoOrder = this.nextTodoOrder - 1;
        this.reorder();
      },
      initTrHeight() {
        this.trSetHeight(null);
        this.$nextTick(() => {
          this.trSetHeight(this.$refs.form.scrollHeight);
        });
      },
      onChange(event) {
        this.reorder();
      },
      reorder() {
        this.requirements.forEach((item, index) => (item.order = index + 1));
      },
      validateRequirementsDesc(){
        let isVal=false
        let vm =this
        let reqInvalid=vm.requirements.filter((r)=>r.description && r.description.length>vm.maxChar)
        reqInvalid.length>0?isVal=true:isVal =false
        return isVal
      },
      showToast(variant, message, title) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon: "CheckIcon",
            text: message,
            variant,
          },
        });
      },
    },
  };
  </script>
  <style scoped>

  </style>
  