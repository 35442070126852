<template>
  <b-modal
    id="construction-options-modal"
    size="lg"
    title="Edit Element"
    cancel-title="Cancel"
    ok-title="Save Element"
    cancel-variant="outline-secondary"
    :no-close-on-backdrop="true"
    @ok="handleOk"
    :ok-disabled="isBusy"
    :cancel-disabled="isBusy"
    @show="resetModal"
    @close="closeModal"
  >
    <b-overlay :show="show" large rounded="lg" spinner-variant="primary">
      <validation-observer ref="accountRules" tag="form">
        <b-row>
          <b-col md="12">
            <h4 style="padding-bottom: 20px">Specification Details</h4>
          </b-col>
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Base Spec Construction Item"
              :rules="{ required: isAdjustment == true }"
            >
              <b-form-group>
                <label for="InputHelp">Specification</label>
                <v-select
                  v-model="constructionItem"
                  label="name"
                  id="select_spec"
                  :options="optionsItems"
                  item-text="name"
                  item-value="is_spec_swap"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="12"
            v-if="
              constructionItem == '0' ||
              (constructionItem && constructionItem.name == 'Custom Specification')
            "
          >
            <validation-provider #default="{ errors }" name="name" rules="required">
              <b-form-group>
                <label for="InputHelp">Specification Name</label>
                <b-input-group>
                  <b-form-input
                    v-model="name"
                    type="text"
                    placeholder="Specification name shown internally only"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="12"
            v-if="
              constructionItem &&
              constructionItem.name == 'Custom Specification' &&
              is_swap_allowed
            "
          >
            <validation-provider
              #default="{ errors }"
              name="Client Description"
              :rules="{
                required: constructionItem.name == 'Custom Specification',
              }"
            >
              <b-form-group>
                <label for="InputHelp">Client Description</label>
                <b-form-textarea
                  id="details"
                  v-model="details"
                  placeholder="Ex. HID-compatible system, 2x suite entry doors"
                  rows="2"
                  :state="details.length <= maxChar"
                  :class="details.length > maxChar ? 'text-danger' : ''"
                />
                <small
                  class="textarea-counter-value float-right"
                  :class="details.length > maxChar ? 'bg-danger' : ''"
                >
                  <span class="char-count">{{ details.length }}</span> /
                  {{ maxChar }}
                </small>
                <template v-if="errors[0]">
                  <small class="text-danger">{{ errors[0] }}</small
                  ><br />
                </template>
                <small class="text-muted"
                  >This field is required when making an specification swap.</small
                >
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="12"
            v-if="
              constructionItem &&
              constructionItem.name == 'Custom Specification' &&
              !is_swap_allowed
            "
          >
            <b-form-group>
              <label for="InputHelp">Client Description</label>
              <b-form-input
                id="details"
                disabled
                v-model="details"
                placeholder="Element has no client facing description."
                rows="2"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Internal Note"
              :rules="{
                required: constructionItem.name != 'Custom Specification',
              }"
            >
              <b-form-group>
                <label for="InputHelp">Internal Note</label>
                <small
                  class="text-muted"
                  v-if="!(budgetGroup == 'Construction' && selectedRadio == 'adjustment')"
                >
                  optional</small
                >
                <b-form-textarea
                  id="internalNote"
                  v-model="internalNote"
                  placeholder="Internal notes and reason for changes."
                  rows="2"
                  :state="internalNote.length <= maxCharInternal"
                  :class="internalNote.length > maxCharInternal ? 'text-danger' : ''"
                />
                <small
                  class="textarea-counter-value float-right"
                  :class="internalNote.length > maxCharInternal ? 'bg-danger' : ''"
                >
                  <span class="char-count">{{ internalNote.length }}</span> /
                  {{ maxCharInternal }}
                </small>
                <template v-if="errors[0]">
                  <small class="text-danger">{{ errors[0] }}</small
                  ><br />
                </template>
                <small
                  v-if="constructionItem.name != 'Custom Specification'"
                  class="text-muted"
                  >This field is required when making an adjustment to base spec.</small
                >
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="12" style="padding-top: 0px">
            <hr style="border: 1px solid rgba(31, 41, 47, 0.05)" />
          </b-col>
          <!-- start:: base adjustment -->
          <b-col cols="12">
            <b-row>
              <b-col md="12">
                <h4 class="py-1">Adjust Pricing</h4>
              </b-col>
              <b-col md="6" v-if="budgetGroup !== 'Construction'">
                <validation-provider
                  #default="{ errors }"
                  name="price"
                  :rules="{ required: budgetGroup !== 'Construction' }"
                >
                  <b-form-group>
                    <label for="InputHelp">Total Price </label>
                    <b-input-group prepend="$">
                      <b-form-input v-model="price" type="number" placeholder="$0.00" />
                    </b-input-group>
                    <small class="labelpersqft"
                      >Price/sqft: {{ price / sqft < 0 ? " -" : "" }}
                      {{ "$" + Math.abs(price / sqft).toFixed(2) }}
                    </small>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <template v-else>
                <b-col md="12" v-if="selectedRadio == 'new'">
                  <validation-provider
                    #default="{ errors }"
                    name="Tender Code"
                    :rules="{ required: budgetGroup == 'Construction' }"
                  >
                    <b-form-group>
                      <label for="InputHelp">Tender Code</label>
                      <v-select
                        v-model="tenderCode"
                        label="code_name"
                        :options="tc"
                        item-text="code_name"
                        required
                        :reduce="(tenderCode) => tenderCode.cost_code"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6" v-if="selectedRadio == 'adjustment'">
                  <b-form-group>
                    <label for="InputHelp">Add or Subtract Quantity</label>
                    <small class="text-muted"> optional</small>
                    <b-input-group>
                      <b-form-input
                        v-model="qty"
                        type="number"
                        placeholder="$0.00"
                        @keyup="constructionPrice()"
                        :readonly="constructionItem == ''"
                        @blur="isEmpty"
                      />
                      <b-input-group-append is-text>
                        {{ isAdjustment ? selectedConstructionItem.unit_type : "" }}
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="selectedRadio == 'adjustment'">
                  <b-form-group>
                    <label for="InputHelp">Add or Subtract COGS / Unit</label>
                    <small class="text-muted"> optional</small>
                    <b-input-group prepend="$">
                      <b-form-input
                        v-model="unitCost"
                        type="number"
                        placeholder="$0.00"
                        @keyup="constructionPrice()"
                        :readonly="constructionItem == ''"
                        @blur="isEmpty"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="selectedRadio == 'adjustment'">
                  <b-form-group>
                    <label for="InputHelp">Add or Subtract Price/Unit </label>
                    <small class="text-muted"> optional</small>
                    <b-input-group prepend="$">
                      <b-form-input
                        v-model="unitPrice"
                        type="number"
                        placeholder="$0.00"
                        @keyup="constructionPrice()"
                        :readonly="constructionItem == ''"
                        @blur="isEmpty"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </template>
              <b-col
                md="6"
                v-if="selectedRadio !== 'adjustment' && budgetGroup == 'Construction'"
              >
                <validation-provider
                  #default="{ errors }"
                  name="price"
                  :rules="{ required: true }"
                >
                  <b-form-group>
                    <label for="InputHelp">Total Price </label>
                    <b-input-group prepend="$">
                      <b-form-input v-model="price" type="number" placeholder="$0.00" />
                    </b-input-group>
                    <small class="labelpersqft"
                      >Price/sqft:{{ price / sqft < 0 ? " -" : "" }}
                      {{ "$" + Math.abs(price / sqft).toFixed(2) }}
                    </small>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" v-if="selectedRadio !== 'adjustment'">
                <validation-provider #default="{ errors }" name="cost" rules="required">
                  <b-form-group>
                    <label for="InputHelp">Total COGS</label>
                    <b-input-group prepend="$">
                      <b-form-input
                        v-model="cost"
                        type="number"
                        placeholder="$0.00"
                        @keyup="constructionPrice()"
                      />
                    </b-input-group>
                    <small class="labelpersqft"
                      >COGS/sqft: {{ cost / sqft < 0 ? " -" : "" }}
                      {{
                        "$" +
                        Number(Math.abs(cost / sqft).toFixed(2)).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                          }
                        )
                      }}</small
                    ><br />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" v-if="base_spec_version>4.2">
                <b-row class="m-0">
                  <b-col class="m-0 p-0" cols="12">
                    <label for="cogs">Other Profit Total</label>
                    <validation-provider
                        name="Other Profit Total"
                        rules="required"
                        #default="{ errors }"
                    >
                      <b-input-group
                          prepend="$">
                        <b-form-input
                            v-model="rebate"
                            placeholder="0"
                            type="number"
                        ></b-form-input> </b-input-group
                      ><small class="text-danger">{{
                        errors[0]
                      }}</small></validation-provider
                    >
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12" v-if="selectedRadio == 'adjustment'">
                <b-row>
                  <b-col md="3">
                    <label
                      style="font-weight: 600;font-size: 12px;line-height: 23px;letter-spacing: 0.6px;text-transform: uppercase;color: #B9B9C3;1"
                      >Quantity</label
                    >
                    <p
                      style="
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #6e6b7b;
                      "
                    >
                      {{
                        constructionItem != ""
                          ? Number(
                              (selectedConstructionItem.qty + qty * 1).toFixed(2)
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            }) +
                            " " +
                            selectedConstructionItem.unit_type
                          : "-"
                      }}
                      <span :class="qty > 0 ? 'increase' : 'decrease'" v-if="qty != 0">
                        <uil-arrow-up v-if="qty > 0" />
                        <uil-arrow-down v-else />{{
                          Number(Math.abs(qty).toFixed(2)).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })
                        }}
                      </span>
                    </p>
                  </b-col>
                  <b-col md="3">
                    <label
                      style="font-weight: 600;font-size: 12px;line-height: 23px;letter-spacing: 0.6px;text-transform: uppercase;color: #B9B9C3;1"
                      >COGS / Unit</label
                    >
                    <p
                      style="
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #6e6b7b;
                      "
                    >
                      {{
                        constructionItem != ""
                          ? "" +
                            Number(
                              (selectedConstructionItem.unit_cost + unitCost * 1).toFixed(
                                2
                              )
                            ).toLocaleString("en-CA", {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "CAD",
                            })
                          : ""
                      }}
                      <span
                        :class="unitCost > 0 ? 'increase' : 'decrease'"
                        v-if="unitCost != 0"
                      >
                        <uil-arrow-up v-if="unitCost > 0" />
                        <uil-arrow-down v-else />{{
                          Number(Math.abs(unitCost))
                            .toFixed(2)
                            .toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                        }}
                      </span>
                    </p>
                  </b-col>
                  <b-col md="3">
                    <label
                      style="font-weight: 600;font-size: 12px;line-height: 23px;letter-spacing: 0.6px;text-transform: uppercase;color: #B9B9C3;1"
                      >Total COGS</label
                    >
                    <p
                      style="
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #6e6b7b;
                      "
                    >
                      {{
                        constructionItem != ""
                          ? Number(
                              Number(selectedConstructionItem.total_cost) +
                                Number(
                                  (selectedConstructionItem.qty + qty * 1) *
                                    (selectedConstructionItem.unit_cost + unitCost * 1) -
                                    selectedConstructionItem.total_cost
                                )
                            ).toLocaleString("en-CA", {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "CAD",
                            })
                          : ""
                      }}
                      <span
                        :class="
                          selectedConstructionItem.total_cost <
                          (selectedConstructionItem.qty + qty * 1) *
                            (selectedConstructionItem.unit_cost + unitCost * 1)
                            ? 'increase'
                            : 'decrease'
                        "
                        v-if="qty != 0 || unitCost != 0"
                      >
                        <uil-arrow-up
                          v-if="
                            selectedConstructionItem.total_cost <
                            (selectedConstructionItem.qty + qty * 1) *
                              (selectedConstructionItem.unit_cost + unitCost * 1)
                          "
                        />
                        <uil-arrow-down v-else />{{
                          Number(
                            Math.abs(
                              selectedConstructionItem.total_cost -
                                (selectedConstructionItem.qty + qty * 1) *
                                  (selectedConstructionItem.unit_cost + unitCost * 1)
                            ).toFixed(2)
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })
                        }}
                      </span>
                    </p>
                  </b-col>
                  <b-col md="3">
                    <label
                      style="font-weight: 600;font-size: 12px;line-height: 23px;letter-spacing: 0.6px;text-transform: uppercase;color: #B9B9C3;1"
                      >Total COGS / sqft</label
                    >
                    <p
                      style="
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #6e6b7b;
                      "
                    >
                      {{
                        ((selectedConstructionItem.qty + qty * 1) *
                          (selectedConstructionItem.unit_cost + unitCost * 1)) /
                          sqft <
                        0
                          ? " -"
                          : ""
                      }}
                      {{
                        constructionItem != ""
                          ? "$" +
                            Number(
                              Math.abs(
                                ((selectedConstructionItem.qty + qty * 1) *
                                  (selectedConstructionItem.unit_cost + unitCost * 1)) /
                                  sqft
                              ).toFixed(2)
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : "-"
                      }}
                      <span
                        :class="
                          selectedConstructionItem.cost_per_sqft <
                          ((selectedConstructionItem.qty + qty * 1) *
                            (selectedConstructionItem.unit_cost + unitCost * 1)) /
                            sqft
                            ? 'increase'
                            : 'decrease'
                        "
                        v-if="qty != 0 || unitCost != 0"
                      >
                        <uil-arrow-up
                          v-if="
                            selectedConstructionItem.cost_per_sqft <
                            ((selectedConstructionItem.qty + qty * 1) *
                              (selectedConstructionItem.unit_cost + unitCost * 1)) /
                              sqft
                          "
                        />
                        <uil-arrow-down v-else />{{
                          Number(
                            Math.abs(
                              selectedConstructionItem.cost_per_sqft -
                                parseFloat(
                                  ((selectedConstructionItem.qty + qty * 1) *
                                    (selectedConstructionItem.unit_cost + unitCost * 1)) /
                                    sqft
                                ).toFixed(2)
                            )
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })
                        }}
                      </span>
                    </p>
                  </b-col>
                  <b-col md="3">
                    <label
                      style="font-weight: 600;font-size: 12px;line-height: 23px;letter-spacing: 0.6px;text-transform: uppercase;color: #B9B9C3;1"
                      >Price/UNIT
                    </label>
                    <p
                      style="
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #6e6b7b;
                      "
                    >
                      {{
                        constructionItem != ""
                          ? Number(
                              selectedConstructionItem.unit_price + unitPrice * 1
                            ).toLocaleString("en-CA", {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "CAD",
                            })
                          : ""
                      }}
                      <span
                        :class="
                          selectedConstructionItem.unit_price <
                          selectedConstructionItem.unit_price + unitPrice * 1
                            ? 'increase'
                            : 'decrease'
                        "
                        v-if="qty != 0 || unitPrice != 0"
                      >
                        <uil-arrow-up
                          v-if="
                            selectedConstructionItem.unit_price <
                            selectedConstructionItem.unit_price + unitPrice * 1
                          "
                        />
                        <uil-arrow-down v-else />{{
                          Number(
                            Math.abs(
                              selectedConstructionItem.unit_price -
                                (selectedConstructionItem.unit_price + unitPrice * 1)
                            ).toFixed(2)
                          ).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </span>
                    </p>
                  </b-col>
                  <b-col md="3">
                    <label
                      style="font-weight: 600;font-size: 12px;line-height: 23px;letter-spacing: 0.6px;text-transform: uppercase;color: #B9B9C3;1"
                      >Total Price
                    </label>
                    <p
                      style="
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #6e6b7b;
                      "
                    >
                      {{
                        constructionItem != ""
                          ? Number(
                              Number(selectedConstructionItem.total_price) +
                                Number(
                                  (selectedConstructionItem.qty + qty * 1) *
                                    (Number(selectedConstructionItem.unit_price) +
                                      Number(unitPrice) * 1) -
                                    Number(selectedConstructionItem.total_price) +
                                    contingencyFeeCalc()
                                )
                            ).toLocaleString("en-CA", {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "CAD",
                            })
                          : ""
                      }}
                      <span
                        :class="
                          selectedConstructionItem.total_price <
                          (selectedConstructionItem.qty + qty * 1) *
                            (Number(selectedConstructionItem.unit_price) +
                              Number(unitPrice) * 1)
                            ? 'increase'
                            : 'decrease'
                        "
                        v-if="qty != 0 || unitPrice != 0"
                      >
                        <uil-arrow-up
                          v-if="
                            selectedConstructionItem.total_price <
                            (selectedConstructionItem.qty + qty * 1) *
                              (Number(selectedConstructionItem.unit_price) +
                                Number(unitPrice) * 1)
                          "
                        />
                        <uil-arrow-down v-else />{{
                          Number(
                            Math.abs(
                              Number(selectedConstructionItem.total_price) -
                                (selectedConstructionItem.qty + qty * 1) *
                                  (Number(selectedConstructionItem.unit_price) +
                                    Number(unitPrice) * 1)
                            ).toFixed(2)
                          ).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </span>
                    </p>
                  </b-col>
                  <b-col md="3">
                    <label
                      style="font-weight: 600;font-size: 12px;line-height: 23px;letter-spacing: 0.6px;text-transform: uppercase;color: #B9B9C3;1"
                      >Total Price/sqft
                    </label>
                    <p
                      style="
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #6e6b7b;
                      "
                    >
                      {{
                        ((selectedConstructionItem.qty + qty * 1) *
                          (Number(selectedConstructionItem.unit_price) * 1 +
                            Number(unitPrice) * 1)) /
                          sqft <
                        0
                          ? ""
                          : ""
                      }}
                      {{
                        constructionItem != ""
                          ? "" +
                            Number(
                              ((selectedConstructionItem.qty + qty * 1) *
                                (Number(selectedConstructionItem.unit_price) +
                                  Number(unitPrice) * 1)) /
                                sqft
                            ).toLocaleString("en-CA", {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "CAD",
                            })
                          : ""
                      }}
                      <span
                        :class="
                          selectedConstructionItem.total_price <
                          (selectedConstructionItem.qty + qty * 1) *
                            (Number(selectedConstructionItem.unit_price) +
                              Number(unitPrice) * 1)
                            ? 'increase'
                            : 'decrease'
                        "
                        v-if="qty != 0 || unitPrice != 0"
                      >
                        <uil-arrow-up
                          v-if="
                            selectedConstructionItem.total_price <
                            (selectedConstructionItem.qty + qty * 1) *
                              (Number(selectedConstructionItem.unit_price) +
                                Number(unitPrice) * 1)
                          "
                        />
                        <uil-arrow-down v-else />{{
                          Number(
                            Math.abs(
                              (Number(selectedConstructionItem.total_price) -
                                (selectedConstructionItem.qty + qty * 1) *
                                  (Number(selectedConstructionItem.unit_price) * 1 +
                                    Number(unitPrice) * 1)) /
                                sqft
                            )
                          ).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </span>
                    </p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <!-- end:: base adjustment -->
        </b-row>
      </validation-observer>
      <template #overlay>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
          <strong> Loading...</strong>
        </div>
      </template>
    </b-overlay>
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="primary" class="float-right ml-1" @click="handleOk">
          Save Element
        </b-button>
        <b-button variant="outline-secondary" class="float-right ml-1" @click="resetSpec">
          Remove Changes
        </b-button>
        <b-button
          variant="outline-secondary"
          class="float-right ml-1"
          @click="closeModal"
        >
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { UilTimes, UilGripHorizontalLine } from "@iconscout/vue-unicons";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormFile,
  BCardText,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BTable,
  BOverlay,
  BFormTextarea,
  BSpinner,
  BFormCheckbox,
  BFormRadioGroup,
  BButton,
} from "bootstrap-vue";
import { required } from "@validations";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import tenderCodes from "@/assets/objects/tender-codes.json";
import { UilArrowUp, UilArrowDown } from "@iconscout/vue-unicons";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCardText,
    BTable,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    vSelect,
    BFormInvalidFeedback,
    LottieAnimation,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormTextarea,
    BSpinner,
    BFormCheckbox,
    BFormRadioGroup,
    UilArrowUp,
    UilArrowDown,
    BButton,
    UilTimes,
    UilGripHorizontalLine,
  },
  props: [
    "quoteId",
    "sqft",
    "constructionItems",
    "base_spec_version",
    "selectedItem",
    "cstrn_adjustment_item_filter",
    "cm_fee_percentage",
    "cm_fee_enabled",
    "contingency_fee_percentage",
  ],
  data() {
    return {
      userName: localStorage.getItem("name"),
      show: false,
      name: "",
      details: "",
      internalNote: "",
      price: 0,
      cost: 0,
      unitCost: 0,
      unitPrice: 0,
      qty: 0,
      budgetGroup: "Construction",
      tenderCode: "",
      constructionItem: "",
      selectedConstructionItem: [],
      required,
      option: ["Construction"],
      maxChar: this.$maxCharDesc,
      maxCharInternal: this.$maxCharInternal,
      isBaseSpec: true,
      tenderCodes: tenderCodes,
      selectedRadio: "adjustment",
      rebate: 0,
      id: null,
      uniformat: null,
      optionsRadio: [
        { text: "New Item", value: "new" },
        { text: "Base Spec Adjustment", value: "adjustment" },
      ],
      isAdjustment: true,
      constructionWorkId: null,
      isBusy: false,
      start_select_state: [],
      // construction_work_id that will have spec swaps
      specs_swap_allowed: [
        5,
        6,
        7,
        8,
        10,
        11,
        12,
        13,
        14,
        31,
        32,
        34,
        36,
        66,
        37,
        40,
        41,
        42,
        43,
        59,
        60,
        61,
        49,
      ],
      is_swap_allowed: false,

      // get codes from uniformat-codes-with-categories.json
      excluded_codes_contingency_fee: [
        "Z1070.30.01",
        "Z1070.30.02",
        "Z1070.30.03",
        "Z1070.40",
        "Z1070.50",
        "Z1070.60",
        "Z1070.80",
        "Z1050.20",
        "Z1050.25",
        "Z1050.30",
        "Z1020.10",
      ],
      excluded_codes_contingency_fee_v2:
          [
            "Z1070.30.03",
            "Z1070.60",
            "Z1020.10",
            "Z1020.10",
            "Z1070.30.02",
            "Z1070.30.02",
            "Z1070.60",
            "Z1050.35",
            "Z1070.20",
            "Z1010.10"
          ],
    };
  },
  computed: {
    tc() {
      let tc = [];
      if (Number(this.base_spec_version) < 3) {
        tc = tenderCodes.v1;
      } else {
        tc = tenderCodes.v2.filter((c) => c.cost_code != "0901");
      }
      return tc;
    },
    getBaseSpec() {
      return Number(this.base_spec_version);
    },
    cstrnItemToUpdate() {
      return this.selectedItem;
    },
    optionsItems() {
      let res = this.constructionItems.filter(
        (i) => i.construction_work_id == this.selectedItem.construction_work_id
      );
      if (res.length > 0) {
        res[0].is_spec_swap = false;
      }
      // * simulate adding spec sets to selection
      // res.push({
      //   name: "Spec Set (not Real)",
      //   construction_work_id: "0",
      //   is_spec_swap: true,
      // });
      // res.push({
      //   name: " another Spec Set (not Real)",
      //   construction_work_id: "0",
      //   is_spec_swap: true,
      // });

      res.push({
        name: "Custom Specification",
        construction_work_id: "0",
        is_spec_swap: true,
      });
      return res;
    },
  },
  watch: {
    selectedItem: function (newVal, oldVal) {
      // watch it
      if (this.cstrn_adjustment_item_filter.length == 0) {
        this.name = "";
        this.details = "";
        this.price = 0;
        this.cost = 0;
        this.isBaseSpec = true;
        this.tenderCode = "";
        this.internalNote = "";
        this.isAdjustment = true;
        this.qty = 0;
        this.unitCost = 0;
        this.unitPrice = 0;
        this.constructionWorkId = null;
        this.uniformat = null;
        this.rebate= 0
      } else {
        this.name = this.cstrn_adjustment_item_filter[0].name;
        this.details =
          this.cstrn_adjustment_item_filter[0].details != null
            ? this.cstrn_adjustment_item_filter[0].details
            : "";
        this.price = this.cstrn_adjustment_item_filter[0].total_price;
        this.cost = this.cstrn_adjustment_item_filter[0].total_cost;
        this.isBaseSpec = this.cstrn_adjustment_item_filter[0].is_base_spec;
        this.tenderCode = this.cstrn_adjustment_item_filter[0].tender_code;
        this.internalNote =
          this.cstrn_adjustment_item_filter[0].internal_note == null
            ? ""
            : this.cstrn_adjustment_item_filter[0].internal_note;
        this.isAdjustment = this.cstrn_adjustment_item_filter[0].is_adjustment;
        this.qty = this.cstrn_adjustment_item_filter[0].quantity;
        this.unitCost = this.cstrn_adjustment_item_filter[0].unit_cost;
        this.unitPrice = this.cstrn_adjustment_item_filter[0].unit_price;
        this.constructionWorkId = this.cstrn_adjustment_item_filter[0].construction_work_id;
      }

      if (
        this.cstrn_adjustment_item_filter.length > 0 &&
        this.cstrn_adjustment_item_filter[0].is_spec_swap
      ) {
        this.constructionItem = this.optionsItems[1];

        this.selectedConstructionItem = newVal;
        this.start_select_state = this.constructionItem;
      } else {
        this.constructionItem = newVal;
        this.selectedConstructionItem = newVal;
        this.start_select_state = this.constructionItem;
      }
      let varRes = this.specs_swap_allowed.filter(
        (s) => s == this.optionsItems[0].construction_work_id
      );
      if (varRes.length > 0) {
        this.is_swap_allowed = true;
      }
      this.uniformat = newVal.uniformat;
      this.rebate = Number((this.cstrn_adjustment_item_filter[0]?.rebate_total?this.cstrn_adjustment_item_filter[0].rebate_total:0)*1);
    },
  },
  mounted() {},
  methods: {
    constructionItemChange(value) {
      if (value == 0) {
      } else {
        this.selectedConstructionItem = this.constructionItems.find(function (e) {
          return e.construction_work_id == value;
        });
        this.isAdjustment = true;
        this.isBaseSpec = true;
        this.constructionWorkId = this.selectedConstructionItem.construction_work_id;
        this.tenderCode = this.selectedConstructionItem.tender_code;
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.validationForm();
    },
    resetModal() {
      if (this.cstrn_adjustment_item_filter.length == 0) {
        this.name = "";
        this.details = "";
        this.price = 0;
        this.cost = 0;
        this.isBaseSpec = true;
        this.tenderCode = "";
        this.internalNote = "";
        this.isAdjustment = true;
        this.qty = 0;
        this.unitCost = 0;
        this.unitPrice = 0;
        this.constructionWorkId = null;
        this.is_swap_allowed = false;
        this.uniformat = null;
        this.rebate = 0;
      } else {
        this.name = this.cstrn_adjustment_item_filter[0].name;
        this.details =
          this.cstrn_adjustment_item_filter[0].details == null
            ? ""
            : this.cstrn_adjustment_item_filter[0].details;
        this.price = this.cstrn_adjustment_item_filter[0].total_price;
        this.cost = this.cstrn_adjustment_item_filter[0].total_cost;
        this.isBaseSpec = this.cstrn_adjustment_item_filter[0].is_base_spec;
        this.tenderCode = this.cstrn_adjustment_item_filter[0].tender_code;
        this.internalNote = this.cstrn_adjustment_item_filter[0].internal_note;
        this.isAdjustment = this.cstrn_adjustment_item_filter[0].is_adjustment;
        this.qty = this.cstrn_adjustment_item_filter[0].quantity;
        this.unitCost = this.cstrn_adjustment_item_filter[0].unit_cost;
        this.unitPrice = this.cstrn_adjustment_item_filter[0].unit_price;
        this.constructionWorkId = this.cstrn_adjustment_item_filter[0].construction_work_id;
        this.rebate = this.cstrn_adjustment_item_filter[0].rebate_total;
      }
      this.constructionItem = this.start_select_state;
      this.uniformat = this.uniformat;
    },
    radioChanged(checked) {
      if (this.isAdjustment == true && checked == "new") {
        this.isAdjustment = false;
        this.qty = 0;
        this.unitCost = 0;
        this.unitPrice = 0;
        this.price = 0;
        this.cost = 0;
        this.selectedConstructionItem = [];
        this.isBaseSpec = false;
        this.constructionItem = "";
        this.constructionWorkId = null;
        this.tenderCode = null;
      } else if (checked == "adjustment") {
        this.isAdjustment = true;
        this.isBaseSpec = true;
      }
    },
    constructionPrice() {},
    isEmpty() {
      if (this.unitCost == "") {
        this.unitCost = 0;
      }
      if (this.unitPrice == "") {
        this.unitPrice = 0;
      }
      if (this.qty == "") {
        this.qty = 0;
      }
    },
    toggleChanged() {
      this.isBaseSpec = false;
      if (this.isAdjustment == true && this.budgetGroup != "Construction") {
        this.isAdjustment = false;
        this.qty = 0;
        this.unitCost = 0;
        this.unitPrice = 0;
        this.selectedConstructionItem = [];
        this.isBaseSpec = false;
        this.constructionItem = "";
        this.selectedRadio = "new";
        this.constructionWorkId = null;
      }
      if (this.budgetGroup == "Construction" && this.selectedRadio == "new") {
        this.price = this.cost = 0;
      } else if (this.budgetGroup == "Construction") {
        this.price = this.cost;
      } else if (this.budgetGroup == "Soft Cost" || this.budgetGroup == "AV/IT") {
        this.price = this.cost;
        this.isBaseSpec = false;
      } else {
        this.tenderCode = null;
        this.price = this.cost = 0;
        this.selectedRadio = "new";
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (this.isAdjustment == true) {
            const totalQty = this.selectedConstructionItem.qty + Number(this.qty);
            const totalUnitCost = this.selectedConstructionItem.unit_cost + Number(this.unitCost);
            const totalUnitPrice = this.selectedConstructionItem.unit_price + Number(this.unitPrice);
            const totalPriceForQty = this.roundToTwo(totalQty * totalUnitPrice);
            this.cost = this.roundToTwo((totalQty * totalUnitCost)) - this.selectedConstructionItem.total_cost;
            this.price = this.selectedConstructionItem.total_price - totalPriceForQty;

            this.price = this.selectedConstructionItem.total_price < totalPriceForQty
                ? Math.abs(this.price)
                : -this.price;
          }
          if (success) {
            this.isBusy = true;
            resolve(true);
            this.show = true;
            if (success) {
              this.isBusy = true;
              resolve(true);
              this.show = true;
              // console.log("*******");
              // console.log(this.cstrn_adjustment_item_filter[0]);
              // console.log("*******");
              // console.log(this.cstrn_adjustment_item_filter[0].id?this.cstrn_adjustment_item_filter[0].id:null);
              this.$emit("updatedOptions", {
                name: this.validateCustomSpec() ? this.name : "",
                details: this.details,
                id: this.cstrn_adjustment_item_filter[0]
                  ? this.cstrn_adjustment_item_filter[0].id
                  : null,
                total_price: this.price,
                total_cost: this.cost,
                cost_per_sqft: this.cost / this.sqft,
                price_per_sqft: this.price / this.sqft,
                budget_group: this.budgetGroup,
                is_base_spec: this.isBaseSpec,
                tender_code: this.optionsItems[0].tender_code,
                quantity: this.qty,
                unit_cost: this.unitCost,
                unit_price: Number(this.unitPrice),
                is_adjustment: this.isAdjustment,
                internal_note: this.internalNote,
                construction_work_id: this.selectedConstructionItem.construction_work_id,
                quote_id: this.quoteId,
                is_spec_swap: this.validateCustomSpec(),
                temp_id: this.selectedItem.temp_id,
                uniformat: this.uniformat,
                rebate_total:  this.rebate
              });
              this.isBusy = false;
              this.show = false;
              this.$bvModal.hide("construction-options-modal");
              // this.$http
              //   .post(`/quote/${this.quoteId}/options`, {
              //     item: this.item,
              //     details: this.details,
              //     sqft: this.sqft,
              //     quoteId: this.quoteId,
              //     total_price: this.price,
              //     total_cost: this.cost,
              //     cost_per_sqft: this.cost / this.sqft,
              //     price_per_sqft: this.price / this.sqft,
              //     budget_group: this.budgetGroup,
              //     is_base_spec: this.isBaseSpec,
              //     tender_code: this.tenderCode,
              //     quantity: this.qty,
              //     unit_cost: this.unitCost,
              //     unit_price: Number(this.unitPrice),
              //     is_adjustment: this.isAdjustment,
              //     internal_note: this.internalNote,
              //     construction_work_id: this.constructionWorkId,
              //   })
              //   .then((response) => {
              //     this.$emit("updatedOptions", response.data);
              //     this.isBusy = false;
              //     this.show = false;
              //     this.$bvModal.hide("options-modal");
              //   })
              //   .catch((error) => {
              //     this.show = false;
              //     this.isBusy = false;
              //     this.showToast("danger", error.response.data.message);
              //   });
            } else {
              reject();
            }
          }
        });
      });
    },
    closeModal() {
      this.isBusy = false;
      this.show = false;
      this.$bvModal.hide("construction-options-modal");
      this.resetModal();
    },
    resetSpec() {
      if (this.cstrn_adjustment_item_filter.length > 0) {
        this.$emit("deleteItem", {
          id: this.cstrn_adjustment_item_filter[0]
            ? this.cstrn_adjustment_item_filter[0].id
            : null,
          temp_id: this.selectedItem.temp_id,
        });
      }
      this.showToast(
        "success",
        `Changes to ${this.optionsItems[0].name} have been removed.`,
        "Successfully removed"
      );
      this.resetModal();
      this.closeModal();
    },
    validateCustomSpec() {
      return this.constructionItem == null ? false : this.constructionItem.is_spec_swap;
    },
    contingencyFeeCalc() {
      let result = 0;
      if (this.base_spec_version > 3.6) {
        result = Number(
          Number(
            Number(this.selectedConstructionItem.total_cost) +
              Number(
                (this.selectedConstructionItem.qty + this.qty * 1) *
                  (this.selectedConstructionItem.unit_cost + this.unitCost * 1) -
                  this.selectedConstructionItem.total_cost
              )
          ) *
            (this.contingency_fee_percentage / 100)
        );
      }

      if (
          this.excluded_codes_contingency_fee.filter(
              (e) => e == this.selectedConstructionItem.uniformat
          ).length > 0 && this.base_spec_version
    > 3.6 && this.base_spec_version <=4.1
      ) {
        result = 0;
      }
      if (
          this.excluded_codes_contingency_fee_v2.filter(
              (e) => e == this.selectedConstructionItem.uniformat
          ).length > 0 && this.base_spec_version >= 4.2
      ) {
        result = 0;
      }
      return result;
    },
    roundToTwo(num) {
      return Math.round(num * 100) / 100;
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" scoped>
.increase {
  color:  rgba(59, 171, 255, 1) ;
  font-size: 12px;
}

.decrease {
  color: #ea5455;
  font-size: 12px;
}
</style>
