<template>
  <b-overlay
    :show="show"
    large
    rounded="lg"
    spinner-variant="primary"
  >
    <div>
      <form-wizard
        ref="form"
        color=" rgba(59, 171, 255, 1) "
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Save Changes"
        back-button-text="Previous"
        @on-complete="formSubmitted"
      >
        <!--General Info Tab-->
        <tab-content
          title="General Info"
          :before-change="validationForm"
        >
          <validation-observer
            ref="accountRules"
            tag="form"
          >
            <b-row>
              <b-col md="12">
                <h5
                  style="
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 24px;
                    margin-bottom: 14px;
                  "
                >
                  General Info
                </h5>
                <validation-provider
                  #default="{ errors }"
                  name="Revision Name"
                  rules="required"
                >
                  <b-form-group>
                    <label for="InputHelp">Revision Name</label>
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        {{ opp.sf_opportunity_number + "-R" }}
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="revision"
                        placeholder="01H"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col :cols="spec_set_selected === 0 || spec_set_selected===null ? 12 : 10" class="my-0">
                <validation-provider
                    #default="{ errors }"
                    name="Specification Set"
                    rules="required"
                >
                  <div>
                    <label>
                      Default Specification Set
                      <uil-info-circle id="spec_set_selected" />
                      <b-tooltip
                          title="This sets the default specifications for elements in this
                    opportunity. Changes will only apply to new quotes."
                          :target="'spec_set_selected'"
                      />
                    </label>
                    <v-select
                        style="z-index: 10000"
                        label="name"
                        :options="spec_sets"
                        item-text="name"
                        :reduce="(option) => option.id"
                        :clearable="false"
                        v-model="spec_set_selected"
                        max-height="100px"
                        class="style-chooser"
                        placeholder="Select Specification Set"

                    />
                  </div>
                  <small class="text-danger" v-if="errors[0]"
                  >{{ errors[0] }}<br
                  /></small>
                </validation-provider>
              </b-col>
              <b-col cols="1" class="p-0 m-0" v-if="spec_set_selected != 0 && spec_set_selected!=null" :cols="spec_set_selected == 0 ? 0 : 2">
                <b-button style="margin-top: 22.5px" class="ml-1" @click="gotoSpecSet()">
                  Review</b-button
                >
              </b-col>
<!--              <b-col :cols="addition_set_selected === 0 || addition_set_selected===null ? 6 : 4" class="my-0">-->
<!--                <validation-provider-->
<!--                    #default="{ errors }"-->
<!--                    name="Addition Set"-->
<!--                    rules="required"-->
<!--                >-->
<!--                  <div>-->
<!--                    <label>-->
<!--                      Default Addition Set-->
<!--                      <uil-info-circle id="addition_set_selected" />-->
<!--                      <b-tooltip-->
<!--                          title="This sets the default additions for elements in this-->
<!--                      opportunity. Changes will only apply to new quotes."-->
<!--                          :target="'addition_set_selected'"-->
<!--                      />-->
<!--                    </label>-->
<!--                    <v-select-->
<!--                        style="z-index: 10000"-->
<!--                        label="name"-->
<!--                        :options="addition_sets"-->
<!--                        item-text="name"-->
<!--                        :reduce="(option) => option.id"-->
<!--                        :clearable="false"-->
<!--                        v-model="addition_set_selected"-->
<!--                        max-height="100px"-->
<!--                        class="style-chooser-addition"-->
<!--                        placeholder="Select Addition Set"-->
<!--                    />-->
<!--                  </div>-->
<!--                  <small class="text-danger" v-if="errors[0]"-->
<!--                  >{{ errors[0] }}<br-->
<!--                  /></small>-->
<!--                </validation-provider>-->
<!--              </b-col>-->
<!--              <b-col  class="p-0 m-0" v-if="addition_set_selected != 0 && addition_set_selected!=null" :cols="spec_set_selected == 0 ? 0 : 2">-->
<!--                <b-button style="margin-top:22.5px" class="ml-1" @click="gotoAdditionSet()">-->
<!--                  Review</b-button-->
<!--                >-->
<!--              </b-col>-->
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Project Address"
                  rules="required"
                >
                  <b-form-group>
                    <label for="InputHelp">Project Address</label>
                    <b-input-group>
                      <b-form-input
                        v-model="address"
                        placeholder="101 - 123 Yonge St."
                      />
                    </b-input-group>
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ errors[0] }}<br></small>
                    <small
                      class="text-muted"
                    >Updating this will update the project page and future
                      revisions.</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="sqft"
                  rules="required"
                >
                  <b-form-group>
                    <label for="InputHelp">Rentable SQFT</label>
                    <b-input-group append="SQFT">
                      <b-form-input
                        v-model="rentable_sqft"
                        type="number"
                        placeholder="ex.100"
                      />
                    </b-input-group>
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ errors[0] }}<br></small>
                    <small
                      class="text-muted"
                    >Updating this will update the project page and future
                      revisions.</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md="12"
                style="padding-top: 30px"
              >
                <h5
                  style="
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 24px;
                    margin-bottom: 0px;
                  "
                >
                  Project Contact
                </h5>
                <small
                  class="text-muted"
                  style="font-weight: 400; font-size: 12px; line-height: 18px"
                >Confirm or update client contact information.</small>
              </b-col>

              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-group>
                    <label for="InputHelp">Name</label>
                    <b-input-group>
                      <b-form-input
                        v-model="contact_name"
                        placeholder="John Smith"
                      />
                    </b-input-group>
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ errors[0] }}<br></small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label for="InputHelp">Phone</label>
                  <small class="text-muted"> optional</small>
                  <b-input-group>
                    <b-form-input
                      v-model="contact_phone"
                      placeholder="905-123-4567"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label for="InputHelp">Email</label>
                  <small class="text-muted"> optional</small>
                  <b-input-group>
                    <b-form-input
                      v-model="contact_email"
                      placeholder="john.smith@theinternet.com"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- personal details tab -->
        <tab-content
          title="Requirements"
          :before-change="validationRequirementsTab"
        >
          <validation-observer
            ref="requirementsRules"
            tag="form"
          >
            <b-col md="12">
              <h5
                style="
                  font-weight: 500;
                  font-size: 15px;
                  line-height: 24px;
                  margin-bottom: 0px;
                "
              >
                Requirements
              </h5>
              <small
                style="
                  color: #6e6b7b;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                "
              >Enter all requirements for this revision. <b>Only</b> itemized
                requirements will be included in this revision.</small>
              <br>
            </b-col>
            <b-col>
              <draggable
                v-model="requirements"
                tag="ul"
                class="list-group list-group-flush cursor-move"
                @change="onChange"
              >
                <b-list-group-item
                  v-for="(item, index) in requirements"
                  :key="index"
                  tag="li"
                  style="border: 1px solid rgba(31, 41, 47, 0.05); border-radius: 5px"
                >
                  <b-row>
                    <b-col
                      md="1"
                      style="
                        padding-right: 13px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 24px;
                        color: #6e6b7b;
                      "
                    >
                      {{ item.order }}
                    </b-col>
                    <b-col md="10">
                      <b-form-group
                        label="Name"
                        label-for="item-name"
                      >
                        <b-form-input
                          id="item-name"
                          v-model="item.name"
                          type="text"
                          placeholder="Ex. Custom bench with shelving"
                        />
                        <small
                          v-if="item.name == ''"
                          class="text-danger"
                        >Please add a name to the requirement.</small>
                      </b-form-group>
                      <b-form-group>
                        <label for="item-description">Specification</label>  <small class="text-muted"> optional</small>
                        <b-form-input
                          id="item-description"
                          v-model="item.description"
                          placeholder="Describe dimensions, finishes, look and feel, location or attach a link of a reference image. Note if this is required for the client or a nice to have."
                          rows="1"
                          :state="
                            item.description
                              ? item.description.length <= maxChar
                              : 0 <= maxChar
                          "
                          :class="
                            item.description
                              ? item.description.length > maxChar
                              : 0 > maxChar
                                ? 'text-danger'
                                : ''
                          "
                        />
                        <small
                          class="textarea-counter-value float-right"
                          :class="
                            item.description
                              ? item.description.length > maxChar
                              : 0 > maxChar
                                ? 'bg-danger'
                                : ''
                          "
                        >
                          <span class="char-count">{{
                            item.description ? item.description.length : 0
                          }}</span>
                          /
                          {{ maxChar }}
                        </small>
                        <!-- <small class="text-danger" v-if="item.description == ''"
                          >Please describe the requirement in more detail.</small
                        > -->
                      </b-form-group>
                    </b-col>
                    <b-col md="1">
                      <b-button
                        style="border: none; background-color: transparent"
                        variant="light"
                        @click="removeItem(index)"
                      ><uil-ban
                        class="logo"
                        size="15"
                      /></b-button>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </draggable>
              <!-- <pre>{{ requirements }}</pre> -->
              <b-row>
                <!-- Name -->
                <b-col md="12">
                  <b-form-group
                    label="Name"
                    label-for="item-name"
                  >
                    <b-form-input
                      id="item-name"
                      v-model="newRequirement.name"
                      type="text"
                      placeholder="Ex. Custom bench with shelving"
                    />
                    <small
                      v-if="arrayLenghtError"
                      class="text-danger"
                    >You need to add at least 1 requirement for the revision</small>
                  </b-form-group>
                  <label for="item-description">Specification</label>
                  <small class="text-muted"> optional</small>
                  <b-form-textarea
                    id="item-description"
                    v-model="newRequirement.description"
                    placeholder="Describe dimensions, finishes, look and feel, location or attach a link of a reference image. Note if this is required for the client or a nice to have."
                    rows="3"
                    :class="
                      newRequirement.description.length > maxChar ? 'text-danger' : ''
                    "
                  />
                  <small
                    class="textarea-counter-value float-right"
                    :class="
                      newRequirement.description.length > maxChar ? 'bg-danger' : ''
                    "
                  >
                    <span class="char-count">{{
                      newRequirement.description.length
                    }}</span>
                    / {{ maxChar }}
                  </small>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                :disabled="addRequirementEnabled"
                @click="addRequirement"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>Add Requirement</span>
              </b-button>
              <validation-provider
                #default="{ errors }"
                name="Context"
              >
                <div class="mb-4">
                  <h5
                    class="mt-2"
                    style="
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 24px;
                    margin-bottom: 0px;
                  "
                  >
                    Context
                    <small class="text-muted"> optional</small>
                  </h5>
                  <small
                    class="text-muted"
                    style="font-weight: 400; font-size: 12px; line-height: 18px"
                  >Note any additional context that would help planners and
                    quoters.</small>
                  <b-form-textarea
                    id="item-description"
                    v-model="notes"
                    placeholder="Note any additional context for planners and quoters. Describe the client, if they are price sensitive or have preferences about their space that may not be captured in the itemized requirements."
                    :state="notes ? notes.length <= 1000 : 0 <= 1000"
                    rows="3"
                    style="margin-top: 14px"
                  />
                  <small
                    class="textarea-counter-value float-right"
                    :class="notes ? notes.length > 1000 ? 'bg-danger' : '': ''"
                  >
                    <span class="char-count">{{
                      notes ? notes.length : 0
                    }}</span>
                    / {{ 1000 }}
                  </small>
                  <small
                    v-if="(notes && notes.length) > 1000"
                    class="text-danger"
                  >Context max length is 1000 characters</small>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>
            </b-col>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </div>
    <template #overlay>
      <div class="text-center">
        <div class="loading">
          <div class="effect-1 effects" />
          <div class="effect-2 effects" />
          <div class="effect-3 effects" />
        </div>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BCardText,
  BInputGroup,
  BInputGroupPrepend,
  BTable,
  BOverlay,
  BFormTextarea,
  BButton,
  BListGroupItem,
  VBToggle,
  BCard,
  BCollapse, BTooltip,
} from 'bootstrap-vue'
import { UilBan, UilInfoCircle } from '@iconscout/vue-unicons'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BTooltip,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormFile,
    BCardText,
    BInputGroup,
    BInputGroupPrepend,
    BTable,
    BOverlay,
    BFormTextarea,
    BButton,
    draggable,
    BListGroupItem,
    AppCollapse,
    AppCollapseItem,
    BCard,
    BCollapse,
    UilBan,
    UilInfoCircle,
    BTooltip
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  mixins: [heightTransition],
  props: ['opp', 'revisionToEdit'],
  data() {
    return {
      revisionModal: [],
      revision: this.revisionToEdit.revision,
      rentable_sqft: this.revisionToEdit.rentable_sqft,
      address: this.revisionToEdit.address,
      contact_name: this.revisionToEdit.contact_name,
      contact_phone: this.revisionToEdit.contact_phone,
      contact_email: this.revisionToEdit.contact_email,
      requirements: this.revisionToEdit.requirements,
      notes: this.revisionToEdit.notes,
      attachments_folder: this.revisionToEdit.attachments_folder_url,
      sf_opportunity_id: this.opp.sf_opportunity_id,
      newRequirement: {
        name: '',
        description: '',
      },
      requirementName: '',
      requirementDescription: '',
      nextTodoOrder: 1,
      arrayLenghtError: false,
      maxChar: this.$maxCharDesc,
      fields: {
        details: '',
      },
      incompleteRequirement: false,
      show: false,
      spec_set_selected: this.revisionToEdit.spec_swap_set_id,
      spec_sets: [
        {
          name: 'Clearspace Base Spec',
          id: 0,
        },
      ],
      addition_set_selected: this.revisionToEdit.spec_swap_set_id,
      addition_sets: [
        {
          name: 'Clearspace Base Spec',
          id: 0,
        },
      ],
    }
  },

  computed: {
    addRequirementEnabled() {
      if (this.newRequirement.name == '') {
        return true
      }
      return false
    },
  },
  mounted() {
    this.getSpecSets()
    // this.getAdditionSets()
    console.log(this.revisionToEdit)
  },
  methods: {
    formSubmitted() {
      return new Promise((resolve, reject) => {
        this.$refs.requirementsRules.validate().then(success => {
          if (success) {
            if (this.validateRequirementsDesc()) {
              return false
            }
            if ((this.notes && this.notes.length) > 1000) {
              return false
            }
            resolve(true)
            this.show = true
            console.log(this.spec_set_selected.id)
            this.$http
              .put(
                `/opportunities/${this.sf_opportunity_id}/tfr/${this.revisionToEdit.id}`,
                {
                  revision: this.revision,
                  rentable_sqft: this.rentable_sqft,
                  address: this.address,
                  contact_name: this.contact_name,
                  contact_phone: this.contact_phone,
                  contact_email: this.contact_email,
                  requirements: this.requirements,
                  notes: this.notes,
                  attachments_folder: this.attachments_folder,
                  spec_set: this.spec_set_selected,
                  // addition_set: this.addition_set_selected,
                },
              )
              .then(response => {
                this.$emit('updatedOptions', response.data.data)
                // this.showToast('success', 'Revision Updated Successfully', 'Revision Updated');
                this.show = false
                this.$bvModal.hide('tfr-update')
              })
              .catch(error => {
                this.show = false
                if (error.response.data.message) {
                  this.showToast(
                    'danger',
                    error.response.data.message,
                    'Something went wrong',
                  )
                } else {
                  this.showToast(
                    'danger',
                    'Contact Digital for support.',
                    'Something went wrong',
                  )
                }
              })
          } else {
            console.log('object')
            reject()
          }
        })
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationRequirementsTab() {
      return new Promise((resolve, reject) => {
        this.$refs.requirementsRules.validate().then(success => {
          const obj = this.requirements.some(item => item.name == '')
          if (obj) {
            this.incompleteRequirement = true
            reject()
          }
          if (this.requirements.length == 0) {
            this.arrayLenghtError = true
          }
          this.incompleteRequirement = false
          if (success) {
            if (this.requirements.length > 0) {
              this.arrayLenghtError = false
              resolve(true)
            }
            if (this.requirements.length == 0) {
              this.arrayLenghtError = true
              reject()
            }
            if ((this.notes && this.notes.length) > 1000) {
              reject()
            }
          } else {
            reject()
          }
        })
      })
    },
    validationContextTab() {
      return new Promise((resolve, reject) => {
        this.$refs.contextRules.validate().then(success => {
          if (success) {
            if (this.notes.length > 1000) {
              reject()
            }
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    addRequirement() {
      this.requirements.push({
        order: this.nextTodoOrder,
        name: this.newRequirement.name,
        description: this.newRequirement.description,
      })
      this.nextTodoOrder += 1
      this.newRequirement.name = ''
      this.newRequirement.description = ''
      this.arrayLenghtError = false
      this.reorder()
    },
    removeItem(index) {
      this.requirements.splice(index, 1)
      this.nextTodoOrder -= 1
      this.reorder()
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    onChange(event) {
      this.reorder()
    },
    reorder() {
      this.requirements.forEach((item, index) => (item.order = index + 1))
    },
    validateRequirementsDesc() {
      let isVal = false
      const vm = this
      const reqInvalid = vm.requirements.filter(r => r.description && r.description.length > vm.maxChar)
      reqInvalid.length > 0 ? isVal = true : isVal = false
      return isVal
    },
    showToast(variant, message, title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'CheckIcon',
          text: message,
          variant,
        },
      })
    },
    getSpecSets() {
      this.$http
        .get('/specifications/sets')
        .then(response => {
          const { data } = response
          this.spec_sets = [this.spec_sets[0], ...data]
          if (this.revisionToEdit.spec_swap_set_id) {
            this.spec_set_selected = this.revisionToEdit.spec_swap_set_id
          } else {
            this.spec_set_selected = this.spec_sets[0].id
          }
        })
        .catch(error => {
          this.show = false
          console.log(error)
          this.showToast('danger', error.response.data.message)
        })
    },
    getAdditionSets() {
      this.$http
          .get('/additions/sets')
          .then(response => {
            const { data } = response
            this.addition_sets = [this.addition_sets[0], ...data]
            if (this.revisionToEdit.addition_set_id) {
              this.addition_set_selected = this.revisionToEdit.addition_set_id
            } else {
              this.addition_set_selected = this.addition_sets[0].id
            }
          })
          .catch(error => {
            this.show = false
            console.log(error)
            this.showToast('danger', error.response.data.message)
          })
    },
    gotoSpecSet() {
      const route = this.$router.resolve(`/catalogue/${this.spec_set_selected}/edit`)
      window.open(route.href)
    },

  },
}
</script>
<style scoped></style>
